var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Breadcrumbs',[_c('template',{slot:"action-buttons"},[(_vm.NoLicense)?_c('v-btn',{staticClass:"pl-5 pr-5",attrs:{"depressed":"","color":"primary"},on:{"click":_vm.upload}},[_vm._v("上传")]):_vm._e()],1)],2),(_vm.NoLicense)?_c('v-alert',{staticClass:"text-left rounded-sm body-2 pa-4 content-wrapper",attrs:{"outlined":"","dense":"","text":"","type":"warning"}},[_vm._v(" 未上传授权信息，须上传授权内容，方可展示授权详细数据！"),_c('span',{staticClass:"red--text"},[_vm._v("请检查授权码匹配硬件码: "+_vm._s(_vm.hardwareKey))]),_vm._v("。 ")]):_vm._e(),(
      !_vm.NoLicense &&
        _vm.authorizationData[0] &&
        _vm.authorizationData[0][1] &&
        Object.keys(_vm.authorizationData[0][1]).length > 0
    )?_c('v-alert',{staticClass:"text-left rounded-sm body-2 pa-4 content-wrapper",attrs:{"outlined":"","dense":"","text":"","type":"info","icon":"mdi-lightbulb-on-outline"}},[_c('span',[_vm._v("授权信息")]),_c('br'),_vm._v(" 该授权附加于产品Webexp，在此基础上支持IAM系统，并详细展示关于此种授权方式的具体内容 ")]):_vm._e(),(
      !_vm.NoLicense &&
        _vm.authorizationData[1] &&
        _vm.authorizationData[1][1] &&
        Object.keys(_vm.authorizationData[1][1]).length > 0
    )?_c('v-alert',{staticClass:"text-left rounded-sm body-2 pa-4 content-wrapper",attrs:{"outlined":"","dense":"","text":"","type":"info","icon":"mdi-lightbulb-on-outline"}},[_c('span',[_vm._v("授权信息")]),_c('br'),_vm._v(" 产品IAM为主要授权方式，并详细展示关于此种授权方式的具体内容 ")]):_vm._e(),(!_vm.NoLicense && _vm.authorizationInfo)?_c('v-card',{staticClass:"dense ml-6 mt-3",attrs:{"elevation":"0"}},[_c('v-card-title',[_vm._v("授权信息")]),_c('v-card-text',[_c('v-row',[_c('v-col',[_c('span',{staticClass:"unselect"},[_vm._v("授权ID：")]),_c('span',{style:({
              color: _vm.authorizationInfo.hardware_code ? '' : '#9195a3'
            })},[_vm._v(" "+_vm._s(_vm.authorizationInfo.hardware_code || "无")+" ")])]),_c('v-col',[_c('span',{staticClass:"unselect"},[_vm._v("产品名称：")]),_c('span',{style:({
              color: _vm.authorizationInfo.product_name ? '' : '#9195a3'
            })},[_vm._v(" "+_vm._s(_vm.authorizationInfo.product_name || "无")+" ")])]),_c('v-col',[_c('span',{staticClass:"unselect"},[_vm._v("产品类型：")]),_c('span',{style:({
              color: _vm.authorizationInfo.purpose ? '' : '#9195a3'
            })},[_vm._v(" "+_vm._s(_vm.authorizationInfo.purpose === "test" ? "测试" : "正式")+" ")])])],1)],1),_c('v-card-text',[_c('v-row',[_c('v-col',[_c('span',{staticClass:"unselect"},[_vm._v("授权类型：")]),_c('span',{style:({
              color: _vm.authorizationInfo.licensetype ? '' : '#9195a3'
            })},[_vm._v(" "+_vm._s(_vm.authorizationInfo.licensetype || "无")+" ")])]),_c('v-col',[_c('span',{staticClass:"unselect"},[_vm._v("客户名称：")]),_c('span',{style:({
              color: _vm.authorizationInfo.customer_name ? '' : '#9195a3'
            })},[_vm._v(" "+_vm._s(_vm.authorizationInfo.customer_name || "无")+" ")])]),_c('v-col',{staticClass:"unselect"},[_c('span',[_vm._v("过期时间：")]),_c('span',{style:({
              color: _vm.expat ? '' : '#9195a3'
            })},[_vm._v(" "+_vm._s((_vm.expat && _vm.expat.replace(/T/, " ")) || "无")+" ")])])],1)],1)],1):_vm._e(),_vm._l((_vm.authorizationData),function(item){return _c('div',{key:item[0]},[(Object.keys(item[1]).length > 0)?_c('v-card',{staticClass:"dense ml-6 mt-3",attrs:{"elevation":"0"}},[_c('v-card-title',[_vm._v(_vm._s(item[0])+"项目特征码")]),_c('v-card-text',[_c('v-row',[_c('v-col',[_c('span',[_vm._v("支持访问控制：")]),_vm._v(" "+_vm._s(item[1].access_control ? "支持" : "不支持")+" ")]),_c('v-col',[_c('span',[_vm._v("可发布应用数量：")]),_vm._v(" "+_vm._s(item[1].app_amount || 0)+" ")]),_c('v-col',[_c('span',[_vm._v("支持国密：")]),_vm._v(" "+_vm._s(item[1].guomi === "1" ? "支持" : "不支持")+" ")])],1),_c('v-row',[_c('v-col',[_c('span',[_vm._v("支持HTTPS：")]),_vm._v(" "+_vm._s(item[1].https === "1" ? "支持" : "不支持")+" ")]),_c('v-col',[_c('span',[_vm._v("支持IAM组件：")]),_vm._v(" "+_vm._s(item[1].iam === "1" ? "支持" : "不支持")+" ")]),_c('v-col',[_c('span',[_vm._v("支持IPV6：")]),_vm._v(" "+_vm._s(item[1].ipv6 === "1" ? "支持" : "不支持")+" ")])],1),_c('v-row',[_c('v-col',[_c('span',[_vm._v("支持远程管理：")]),_vm._v(" "+_vm._s(item[1].remote_control === "1" ? "支持" : "不支持")+" ")]),_c('v-col',[_c('span',[_vm._v("可管理Worker数量：")]),_vm._v(" "+_vm._s(item[1].worker_amount || 0)+" ")]),_c('v-col')],1)],1)],1):_vm._e()],1)}),_c(_vm.sheet_editor,{tag:"Component",attrs:{"hardwareKey":_vm.hardwareKey}})],2)}
var staticRenderFns = []

export { render, staticRenderFns }